import { intersection } from "lodash";

export function isLoggedIn() {
  /*
   * Note:
   *  This app assume if local storage have roles it means
   *  user is authenticated you can update this logic as per your app.
   */
  return localStorage.getItem("roles");
}
export function isLoggedInUser() {
  /*
   * Note:
   *  This app assume if local storage have roles it means
   *  user is authenticated you can update this logic as per your app.
   */
  return localStorage.getItem("user");
}

export function isArrayWithLength(arr) {
  return Array.isArray(arr) && arr.length;
}

export function GetAllowedRoutes(routes) {
  const roles = JSON.parse(localStorage.getItem("roles"));

  return routes.filter(({ permission, path }, item) => {
    if (localStorage.getItem("isAssociatedClinic") === 0) {
      if (path === "/") {
        return false;
      } else if (path === "/notificationSummary") {
        return false;
      }
    }

    if (!permission) {
      return true;
    } else if (!isArrayWithLength(permission)) {
      return true;
    } else {
      return intersection(permission, [roles]).length;
    }
  });
}

// To check if string has only alphabers with/out space
export function alphabetsOnlyWithSpace(str) {
  let regx = /^[A-Za-z]*$/;
  if (!str) {
    return true;
  }
  let arr = str.split(" ");
  for (let i = 0; i < arr.length; i++) {
    if (!regx.test(arr[i])) {
      return false;
    }
  }
  return true;
}

/*
@params
Pass any string to encode that to  base64
*/
export const base64Encode = (str) => {
  const buffer = Buffer.from(str, "utf-8");
  return buffer.toString("base64");
};
/*
@params
Pass any string to decode that to  base64
*/
export const base64Decode = (encodedStr) => {
  const buffer = Buffer.from(encodedStr, "base64");
  return buffer.toString("utf-8");
};

export const downloadFn = (data, reportName) => {
  const blob = new Blob([data], { type: "text/csv" });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.setAttribute("hidden", "");
  a.setAttribute("href", url);
  a.setAttribute("download", reportName);
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export const objectToCSV = (data) => {
  //get header
  const csvRows = [];
  if (data[0]) {
    const headers = Object.keys(data[0]);
    csvRows.push(headers.join(","));
    //loops over the row
    for (const row of data) {
      const values = headers.map((header) => {
        const escaped = (" " + row[header]).replace(/"/g, '\\"');
        return `"${escaped}"`;
      });
      csvRows.push(values.join(","));
    }
    //from escaped comma seprater val
    return csvRows.join("\n");
  }
};

export const generateQueryParams = (obj) => {
  const params = new URLSearchParams();
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      params.append(key, obj[key]);
    }
  }
  return params.toString();
};
export const convertToLocaleTime = (dateString) => {
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return new Date(dateString).toLocaleString("en-US", { timeZone: userTimeZone });
};
export const formatDate = (timestamp, time = false) => {
  const date = new Date(timestamp); // Convert timestamp to Date object
  const day = String(date.getDate()).padStart(2, '0'); // Ensure 2-digit day
  const month = date.toLocaleString('en-US', { month: 'short' }); // Short month name
  const year = date.getFullYear(); // Full year
  if (!time) return `${month} ${day}, ${year}`
  else if (time) {
    const hours = String(date.getHours()).padStart(2, '0'); // 2-digit hour
    const minutes = String(date.getMinutes()).padStart(2, '0'); // 2-digit minute
    const seconds = String(date.getSeconds()).padStart(2, '0'); // 2-digit second
    return `${month}  ${day}, ${year} ${hours}:${minutes}:${seconds}`;
  }
}
export const isGraphDataEmpty = (mergedData) => {
  for (const key in mergedData) {
    const dataArray = mergedData[key];
    for (const item of dataArray) {
      if (item.x.length === 0 && item.y.length === 0) {
        return true;
      }
    }
  }
  return false;
}

export function getYearFromDate(dateString) {
  const parts = dateString.split("-"); // Split date into [DD, MM, YYYY]
  return parts.length === 3 ? parseInt(parts[0], 10) : NaN; // Extract and return the year
}
export const formatDateTime = (dateTime, time = false) => {
  if (!dateTime) return '';
  
  // Create a date object that preserves the exact date without timezone conversions
  // First, split the date string to get year, month, and day
  const [year, month, day] = dateTime.split('-').map(num => parseInt(num, 10));
  
  // Create a date object with the exact date (months are 0-indexed in JavaScript)
  const date = new Date(year, month - 1, day);
  
  // Format the date part
  const options = { year: 'numeric', month: 'short', day: 'numeric' };
  const formattedDate = date.toLocaleDateString('en-US', options).toUpperCase();
  
  // Format the time part if needed
  let formattedTime = '';
  if (time) {
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    formattedTime = `${hours}:${minutes}:${seconds}`;
  }
  
  return `${formattedDate}${time ? ' ' + formattedTime : ''}`;
};
export const formatDateToISO = (dateString) => {
  if (!dateString) return null;
  const [day, month, year] = dateString.split('-'); // Split "25-06-2024" into parts
  return `${year}-${month}-${day}`; // Rearrange to "YYYY-MM-DD"
};
