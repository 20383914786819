import React, { createContext, useContext, useRef, useMemo } from "react";
import { driver } from "driver.js";
import "driver.js/dist/driver.css";

// Create context with default values
const TourContext = createContext({
  startTour: () => {}
});

export const TourProvider = ({ children }) => {
  // Use useRef to maintain the driver instance without causing re-renders
  const driverRef = useRef(null);
  
  // Initialize the driver instance only once when it's needed
  const initializeDriver = () => {
    if (!driverRef.current) {
      driverRef.current = driver({
        animate: true,
        overlayOpacity: 0.75,
        allowClose: true,
        overlayClickBehavior: "nextStep",
        showButtons: ["next", "previous", "close"],
        nextBtnText: "Next ▶",
        prevBtnText: "Back ◀",
        doneBtnText: "Finish ✅",
        closeBtnText: "Exit Tour ⏹",
      });
    }
    return driverRef.current;
  };

  // Define the tour steps
  const tourSteps = [
    {
      element: "#logo",
      popover: {
        title: "ABM Respiratory Care",
        description: "Click here to return to the homepage.",
        side: "bottom",
        showButtons: ["next", "close"],
      },
    },
    {
      element: "#date-picker",
      popover: {
        title: "Date Range Selector",
        description: "Use this to filter therapy data by date range.",
        side: "bottom",
        showButtons: ["next", "previous", "close"],
      },
    },
    {
      element: "#therapy-list",
      popover: {
        title: "Therapy List",
        description: "Shows a list of all therapies available.",
        side: "right",
        showButtons: ["next", "previous", "close"],
      },
    },
    {
      element: "#graph",
      popover: {
        title: "Settings Vs Pressures and Volumes",
        description: "Provides a visual representation of therapy settings and delivered pressures/volumes. Zoom in for better visibility.",
        side: "right",
        showButtons: ["next", "previous", "close"],
      },
    },
    {
      element: "#add-graph",
      popover: {
        title: "Add Graph to Report",
        description: "Click on 'Add Graph' to move the current view to the report.",
        side: "left",
        showButtons: ["next", "previous", "close"],
      },
    },
    {
      element: "#generate-report-btn",
      popover: {
        title: "Generate Report",
        description: "Hover here to generate and download a therapy report. Preview the selected graphs before downloading.",
        side: "left",
        showButtons: ["done", "previous", "close", "next"],
      },
    },
  ];

  // Function to start the tour
  const startTour = () => {
    const driverInstance = initializeDriver();
    
    // Delay setting steps slightly to ensure DOM elements are loaded
    setTimeout(() => {
      driverInstance.setSteps(tourSteps);
      driverInstance.drive();
    }, 100);
  };
  
  // Memoize the context value to prevent unnecessary re-renders
  const contextValue = useMemo(() => ({ startTour }), []);

  return (
    <TourContext.Provider value={contextValue}>
      {children}
    </TourContext.Provider>
  );
};

export const useTour = () => useContext(TourContext);

export default TourContext;